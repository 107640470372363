<div class="phx-checkbox-container">
  <input (change)="changed($event)"
         (click)="clicked($event)"
         [checked]="checked"
         [disabled]="disabled"
         [attr.data-testid]="dataTestId"
         class="phx-checkbox"
         [ngClass]="checkboxClass"
         type="checkbox" />
  <span *ngIf="checkedText != '' || uncheckedText != '' || text != ''">
  {{ checked ? (checkedText || text) : (uncheckedText || text) }}
  </span>
</div>

