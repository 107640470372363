export enum InvitationType {
  EMAIL = 0,
  BCS = 1
}

export enum ServiceTypeBehavior {
  CERT_GROUP = 'CERT_GROUP',
  COMPANY_GROUP = 'COMPANY_GROUP',
  NONE = 'NONE'
}

export enum ExportType {
  VENDOR = 'vendor',
  TENANT = 'tenant',
  JOB = 'job',
  LEASE = 'lease'
}

export enum SettingsType {
  VENDORS = 'VENDORS',
  TENANTS = 'TENANTS',
  JOBS = 'JOBS',
  LEASES = 'LEASES'
}

export enum WaiverType {
  LIMIT = 1,
  POLICY = 2,
  ENDORSEMENT = 3,
  CERT_HOLDER = 4,
  AM_BEST = 5,
  DOCUMENT = 6,
  ADDITIONAL_TERM = 7
}

export enum ClientType {
  VENDOR_PAY = 'VENDOR_PAY',
  SELF_SERVICE = 'SELF_SERVICE',
  CLIENT_PAY = 'CLIENT_PAY',
  FREEMIUM = 'FREEMIUM',
}

export enum CertusClientType {
  Vendor = 1,
  Tenant = 2
}

export enum ContactType {
  Client = 1,
  BCSConnectVendor = 2,
  DoNotUse = 3,
  RequesterContact = 4,
  BCSConnectTenant = 5,
  DoNotContact = 6,
  AutomationContact = 7
}
