import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * A custom checkbox component
 *
 * @example
 *  <phx-checkbox
 *    checkedText="This Box Was Checked"
 *    uncheckedText="This Box Was Unchecked"
 *    text="This Is Radnom Text"
 *    [checked]="true"
 *    [disabled]="false"
 *    dataTestId="dataTestId"
 *    checkboxClass="class"
 *    clickEventEmitter="clicked()"
 *    changeEventEmitter="changed()">
 *  </phx-checkbox>
 */
@Component({
  selector: 'phx-checkbox',
  templateUrl: './phoenix-checkbox.component.html',
  styleUrls: ['./phoenix-checkbox.component.scss']
})
export class PhoenixCheckboxComponent {
  /**
   * #### checkedText: string
   *  This is the text that displays if the checkbox is checked.
   * @type {string}
   */
  @Input() checkedText?: string = '';

  /**
   * #### uncheckedText: string
   *  This is the text that displays if the checkbox is unchecked.
   * @type {string}
   */
  @Input() uncheckedText?: string = '';

  /**
   * #### text: string
   *  This is the text that remains visible at all times.
   * @type {string}
   */
  @Input() text?: string = '';

  /**
   * #### checked: boolean
   *  Determines whether checkbox is checked or not
   * @type {boolean}
   */
  @Input() checked: boolean;

  /**
   * #### disabled: boolean
   *  Determines whether checkbox is disabled
   * @type {boolean}
   */
  @Input() disabled: boolean;

  /**
   * #### dataTestId: string
   *  This is the system test id
   * @type {string}
   */
  @Input() dataTestId: string;

  /**
   * #### checkboxClass: string
   *  CSS class for the checkbox
   * @type {string}
   */
  @Input() checkboxClass?: string = '';

  /**
   * #### clickEventEmitter: EventEmitter<any>
   *  Emits an event on checkbox click
   * @type {EventEmitter<any>}
   */
  @Output() clickEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  /**
   * #### changeEventEmitter: EventEmitter<any>
   *  Emits an event on checkbox change
   * @type {EventEmitter<any>}
   */
  @Output() changeEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  clicked(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.disabled) {
      this.clickEventEmitter.emit(event);
    }
  }

  changed(event: Event): void {
    event.stopPropagation();
    if (!this.disabled) {
      this.changeEventEmitter.emit(event);
    }
  }
}
