import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { PhoenixWebService } from '@capp/providers/web.service';
import { AppService } from '../../services/app.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from '../../../../../phoenix-common/src/lib/auth/auth-service';
import { UserPermissionsService } from '@capp/providers/user-permissions.service';
import { Router } from '@angular/router';
import { UserService } from '@capp/app/services/user.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'phx-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  public modalRef: BsModalRef;

  public image: any;
  public loading: boolean;
  public companyName = '';
  public clientSwitcherClients: {
    phoenixClientId: number;
    name: string
  }[] = [];
  public filteredClientSwitcherClients: {
    phoenixClientId: number;
    name: string
  }[] = [];
  public currentClientId: number;
  public switchClientsDropdownShown: boolean;
  clientSearchValue: string;
  searchPipe = new Subject<string>();
  private destroy$ = new Subject<void>();

  constructor(private phoenixWebService: PhoenixWebService,
              public appService: AppService,
              private authService: AuthService,
              public userPermissionsService: UserPermissionsService,
              public userService: UserService,
              public router: Router,
              private elementRef: ElementRef) {
    this.initializeSearchPipe();
  }

  async ngOnInit(): Promise<void> {
    this.currentClientId = this.appService.phoenixClientId;
    this.companyName = this.appService.clientName;
    await this.loadClientSwitcherClients();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.switchClientsDropdownShown = false;
    }
  }

  public async loadClientSwitcherClients() {
    try {
      this.clientSwitcherClients = (await this.phoenixWebService.getClientSwitcherClients())?.availableClients;
      this.filteredClientSwitcherClients = this.clientSwitcherClients;
    }
    catch {
      this.clientSwitcherClients = [];
    }
  }

  public showSwitchClientsDropdown() {
    if (this.clientSwitcherClients?.length > 1) {
      this.switchClientsDropdownShown = !this.switchClientsDropdownShown;
    }
  }

  public async switchClients(clientId: number,
                             name: string) {
    this.switchClientsDropdownShown = false;
    if (clientId === null) {
      this.cancelClientSwitch();
      return;
    }
    const confirmResult = await this.appService.confirmModal('Are you sure you want to switch client to ' + name + '?');
    if (!confirmResult) {
      this.cancelClientSwitch();
    } else {
      try {
        this.appService.isClientSwitching(true);
        await this.phoenixWebService.switchPhoenixClients(clientId);
        await this.router.navigate([
          'main',
          'my-accounts',
          'v'
        ]);
        this.authService.refreshSession(true);
      }
      catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        this.appService.showAlertMessage('Failed switching clients.', 'danger');
      }
    }
  }

  public cancelClientSwitch() {
    this.currentClientId = this.appService.phoenixClientId;
    this.switchClientsDropdownShown = false;
  }

  filterClients(text: string) {
    this.clientSearchValue = text;
    this.searchPipe.next(text);
  }

  private initializeSearchPipe() {
    this.searchPipe
        .pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
        .subscribe((value) => {
          this.filteredClientSwitcherClients = value
                                               ? this.clientSwitcherClients.filter(client => client.name.toLowerCase()
                                                                                                   .includes(value.toLowerCase()))
                                               : [...this.clientSwitcherClients];
        });
  }
}
