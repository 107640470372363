import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

/**
 * A single-select dropdown component enabling users to type and select their preferred option.
 *
 * @example
 *  <phx-single-select
 *    [options]="optionsList"
 *    valueField="id"
 *    textField="value"
 *    [disabled]="false"
 *    [selectedItem]="selectedDropdownItem"
 *    size="medium"
 *    placeholder="Placeholder Text"
 *    itemClass="class-name"
 *    emptyDropdownMessage="No Options Found"
 *    [error]="false"
 *    dataTestId="dataTestId"
 *    customClass="class-name"
 *    (changeEventEmitter)="onItemSelected($event)"
 *    (errorEventEmitter)="onError()">
 *  </phx-single-select>
 */
@Component({
  selector: 'phx-single-select',
  templateUrl: './phoenix-single-select.component.html',
  styleUrls: ['./phoenix-single-select.component.scss']
})
export class PhoenixSingleSelectComponent implements OnChanges {
  /**
   * #### options: any[]
   *  The selectable options
   * @type {any[]}
   */
  @Input() options: any[] = [];

  /**
   * #### valueField: string
   *  The id of the option object you are selecting from
   * @type {string}
   */
  @Input() valueField?: string = 'value';

  /**
   * #### textField: string
   *  The text/value of the option object you are selecting from
   * @type {string}
   */
  @Input() textField?: string = 'label';

  /**
   * #### disabled: boolean
   *  Determines if the select box is disabled
   * @type {boolean}
   */
  @Input() disabled: boolean;

  /**
   * #### selectedItem: any
   *  The selected item
   * @type {any}
   */
  @Input() selectedItem: any;

  /**
   * #### size: string
   *  The size of the input
   * @type {string}
   */
  @Input() size: string;

  /**
   * #### placeholder: string
   *  The placeholder
   * @type {string}
   */
  @Input() placeholder?: string = '';

  /**
   * #### itemClass: string
   *  The css class for the selectable items
   * @type {string}
   */
  @Input() itemClass: string;

  /**
   * #### emptyDropdownMessage: string
   *  This message displays in the dropdown if there are no more selectable options
   * @type {string}
   */
  @Input() emptyDropdownMessage?: string = '';

  /**
   * #### error: boolean
   *  Determines if there was an error. If so, we change the
   *  state of the single-select and add a red border around it
   * @type {boolean}
   */
  @Input() error: boolean;

  /**
   * #### dataTestId: string
   *  System Test Id
   * @type {string}
   */
  @Input() dataTestId: string;

  /**
   * #### customClass: string
   *  Extra css class for component
   * @type {string}
   */
  @Input() customClass: string;

  /**
   * #### changeEventEmitter: EventEmitter<any>
   *  Event that's called when the select box state changes
   * @type {EventEmitter<any>}
   */
  @Output() changeEventEmitter: EventEmitter<any> =
    new EventEmitter<any>();

  /**
   * #### errorEventEmitter: EventEmitter<any>
   *  Event that's called when an error occurs
   * @type {EventEmitter<any>}
   */
  @Output() errorEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('phxMultiSelect', { static: false }) phxMultiSelect: ElementRef;
  @ViewChild('inputElement') inputElement: ElementRef;
  @ViewChild('dropdown') dropdown: BsDropdownDirective;
  public input = '';
  public filteredOptions = [];
  public showDropDown = false;
  public ShowDropDownTimeout: any;
  public isHovering = false;
  public dropdownId = this.generateRandomAlphanumeric();

  get isDisabled() {
    return (this.disabled || this.options == null);
  }

  ngOnChanges(): void {
    this.filteredOptions = this.options?.slice(0);
    this.setInputField();
  }

  onMouseEnter() {
    this.isHovering = true;
  }

  onMouseLeave() {
    this.isHovering = false;
  }

  setInputField() {
    if (!this.selectedItem) {
      this.input = '';
    } else {
      this.input = this.selectedItem[this.textField];
    }
    this.onSearch();
  }

  public toggleDropDown(didClickButton = false, forceToValue = null) {
    if (didClickButton) {
      if (forceToValue == null) {
        this.showDropDown = !this.showDropDown;
      } else {
        this.showDropDown = forceToValue;
      }
      if (this.ShowDropDownTimeout) {
        clearTimeout(this.ShowDropDownTimeout);
      }
      if (this.showDropDown && !this.selectedItem) {
        this.setFocus();
      }
      return;
    }

    this.ShowDropDownTimeout = setTimeout(() => {
      if (forceToValue == null) {
        this.showDropDown = !this.showDropDown;
      } else {
        this.showDropDown = forceToValue;
      }
    }, 100);
  }

  public onSearch() {
    if (!this.selectedItem) {
      this.filteredOptions = this.filterOptions();
    }
  }

  public onSelectedItem(evt: any, item: any, didClick = false) {
    if (item == null && this.selectedItem != null) {
      this.setInputField();
    }
    this.selectedItem = item;

    if (item != null) {
      this.setInputField();
    }
    this.changeEventEmitter.emit(this.selectedItem);
    this.onSearch();

    this.error = false;
    this.toggleDropDown(didClick, false);
  }

  public removedItem(evt: any) {
    evt.stopPropagation();
    this.input = '';
    this.onSearch();

    this.selectedItem = null;
    this.changeEventEmitter.emit(this.selectedItem);
    this.setFocus();
  }

  public filterOptions() {
    if (this.options == null) {
      return null;
    }
    return this.options.filter((f) =>
      f?.[this.textField]?.toLocaleLowerCase().includes(this.input.toLocaleLowerCase())
    );
  }

  public setFocus() {
    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    }, 0);
  }

  public onFocusGained() {
    this.toggleDropDown(false, true);
  }

  public onFocusLost() {
    setTimeout(() => {
      const focusedElement = document.activeElement;
      if (focusedElement && focusedElement.classList.contains('focusableItems')) {
        //focus just shifted to the dropdown list... don't toggle
      } else {
        this.toggleDropDown(false, false);
      }
    }, 100);
  }

  public onKeyPress(evt: any) {
    if (evt.code === 'ArrowUp' || evt.code === 'ArrowDown') {
      if (this.ShowDropDownTimeout) {
        clearTimeout(this.ShowDropDownTimeout);
      }
      const counter: number = evt.code === 'ArrowUp' ? -1 : 1;
      const lastTabIndex = this.filteredOptions.length;
      const tabbables: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName(`${this.dropdownId}-option`) as HTMLCollectionOf<HTMLElement>;
      let currentElement: HTMLElement = null;
      for (let i = 0; i <= tabbables.length - 1; i++) {
        if (tabbables[i] === document.activeElement) {
          currentElement = tabbables[i];
        }
      }

      let curIndex = currentElement?.tabIndex ?? 0;
      if (curIndex === lastTabIndex && counter > 0) {
        curIndex = 0;
      } else if (curIndex === 1 && counter < 0) {
        curIndex = lastTabIndex + 1;
      }

      for (let i = 0; i <= tabbables.length - 1; i++) {
        if (tabbables[i].tabIndex === (curIndex + counter)) {
          tabbables[i].focus(); //if it's the one we want, focus it and exit the loop
          break;
        }
      }
    }
  }

  public selectItemWithKeyPress(evt: any, item: any) {
    if (evt.code === 'Enter' || evt.code === 'NumpadEnter') {
      this.onSelectedItem(evt, item, true);
    }
  }

  public generateRandomAlphanumeric(length = 6): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  }

  checkLeaveFocus($event) {
    if ($event.relatedTarget && $event.relatedTarget.className.includes('dropdown-item')) {
      return;
    } else {
      this.dropdown.hide();
    }
  }
}
