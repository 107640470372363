import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { Utils } from '../shared/utils/utils';

/**
 * A custom input component for all types of inputs including:
 * text, date, currency, textbox, and number
 *
 * @example
 *  <phx-input
 *    inputType="currency"
 *    inputValue="value"
 *    [disabled]="false"
 *    placeholder="Placeholder Text"
 *    class="class-name"
 *    size="medium"
 *    textAreaRowSize="1"
 *    textAreaColSize="1"
 *    [hasError]="false"
 *    title="title"
 *    [centerText]="false"
 *    [rightAlignText]="false"
 *    id="id"
 *    dataTestId="dataTestId"
 *    (changeEventEmitter)="onChange($event)"
 *    (focusEventEmitter)="onFocus()">
 *  </phx-input>
 */
@Component({
  selector: 'phx-input',
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    NgClass,
    ReactiveFormsModule
  ],
  templateUrl: './phoenix-input.component.html',
  styleUrl: './phoenix-input.component.scss'
})
export class PhoenixInputComponent implements OnInit {
  /**
   * #### inputType: string
   *  The type of input
   * @type {string}
   */
  @Input() inputType?: string = 'text'; // text, date, currency, number, textarea

  /**
   * #### inputValue: any
   *  The input's value
   * @type {any}
   */
  @Input() inputValue?: any = ''

  /**
   * #### disabled: boolean
   *  Determines if the input box is disabled
   * @type {boolean}
   */
  @Input() disabled: boolean;

  /**
   * #### placeholder: string
   *  The placeholder
   * @type {string}
   */
  @Input() placeholder?: string = '';

  /**
   * #### class: string
   *  The css class for the component
   * @type {string}
   */
  @Input() class: string;

  /**
   * #### size: string
   *  The size of the input box
   * @type {string}
   */
  @Input() size: string;

  /**
   * #### textAreaRowSize: string
   *  The number of rows for the text area
   * @type {string}
   */
  @Input() textAreaRowSize: string;

  /**
   * #### textAreaColSize: string
   *  The number of columns for the text area
   * @type {string}
   */
  @Input() textAreaColSize: string;

  /**
   * #### hasError: boolean
   *  Determines if an error has occurred for the input box
   * @type {boolean}
   */
  @Input() hasError: boolean;

  /**
   * #### title: string
   *  The title for the input. This text display when you hover over the input.
   * @type {string}
   */
  @Input() title?: string = '';

  /**
   * #### centerText: boolean
   *  Center the text inside the input box
   * @type {boolean}
   */
  @Input() centerText: boolean;

  /**
   * #### rightAlignText: boolean
   *  Right Align the text inside the input box
   * @type {boolean}
   */
  @Input() rightAlignText: boolean;

  /**
   * #### id: string
   *  The id of the component
   * @type {string}
   */
  @Input() id: string;

  /**
   * #### dataTestId: string
   *  System Test Id
   * @type {string}
   */
  @Input() dataTestId: string;

  /**
   * #### changeEventEmitter: EventEmitter<any>
   *  Event that's emitted whenever the state of the input changes
   * @type {EventEmitter<any>}
   */
  @Output() changeEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  /**
   * #### focusEventEmitter: EventEmitter<any>
   *  Event that's emitted whenever the input is focused
   * @type {EventEmitter<any>}
   */
  @Output() focusEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    if (this.inputType == 'currency' && parseInt(this.inputValue) >= 0 && this.disabled) {
      this.inputValue = this.formatNumberToCurrencyString(parseInt(this.inputValue));
    }
  }

  onInputChange() {
    if (this.inputType != 'currency') {
      this.changeEventEmitter.emit(this.inputValue);
    } else {
      this.onCurrencyInputChange();
    }
  }

  onFocusChange() {
    this.focusEventEmitter.emit();
  }

  //#region CURRENCY
  onCurrencyInputChange() {
    const values = Utils.formatMoney(this.inputValue);
    this.inputValue = values.displayValue;
    this.changeEventEmitter.emit(values.displayValue);
  }

  formatNumberToCurrencyString(amount: number): string {
    const USDollar: Intl.NumberFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    });

    return USDollar.format(amount);
  }

  onCurrencyKeyDown(evt: KeyboardEvent) {
    // Define allowed characters
    const allowedChars = '0123456789';

    // Prevent default if character is not allowed
    if (allowedChars.indexOf(evt.key) === -1 && evt.key !== 'Backspace' && evt.key !== 'ArrowLeft' && evt.key !== 'ArrowRight') {
      evt.preventDefault();
    }
  }

  //#endregion

  showRealDate(title: string) {
    try {
      const date = new Date(title + 'T00:00:00Z');

      const formatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC'
      });

      return formatter.format(date);
    } catch (err: any) {
      return err?.error?.detail ?? '';
    }
  }
}
