import { Injectable, TemplateRef } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertModel } from '../../../../phoenix-common/src/lib/shared/models/alert-model';
import { AuthenticatedUserService } from './authenticated-user.service';
import { ClientDataService } from '@capp/app/services/clientData.service';
import { CertusClientType, ClientType, ServiceTypeBehavior } from '../../../../phoenix-common/src/lib/shared/utils/Enumerations';

@Injectable({ providedIn: 'root' })
export class AppService {
  public token: string = null;
  public topBarLeftContent: TemplateRef<any>;
  public backRowContent: TemplateRef<any>;
  public topBarCenterContent: TemplateRef<any>;
  public topBarTabContent: TemplateRef<any>;
  public topBarBottomMiddleContent: TemplateRef<any>;
  public leaseTypesArr: Array<any>;
  public leaseTypesMap = {};
  public jobTradesArr: Array<any>;
  public jobTradesMaps = {};
  public jobTypesArr: Array<any>;
  public jobTypesMap = {};
  public loginDisplayLoader: boolean;
  public displayClientSwitchLoader: boolean;

  // Confirm Modal
  public modalPromiseResolve: any = null;
  public modalRef: BsModalRef = null;
  public modalConfirmTemplate: any;
  public modalConfirmTitleSubject: Subject<string> = new Subject();

  // Information Modal
  public modalInformationTemplate: any;
  public modalInformationTitleSubject: Subject<string> = new Subject();

  // Client Delinquent Modal
  public modalClientDelinquentTemplate: any;

  // Alert
  public alertMessageSubject: Subject<AlertModel> = new Subject();
  public alertMessagesSubject: Subject<any> = new Subject();

  // Notification panel
  public notificationsPanelStateSubj = new ReplaySubject<any>();

  // Observable sources
  public jobTradesSource = new Subject<any>();
  public jobTypesSource = new Subject<any>();
  public leaseTypesSource = new Subject<any>();
  public newReviewSource = new Subject<any>();
  public selectedVendorProfileSource = new ReplaySubject<any>(1);

  // Observable streams
  public jobTrades = this.jobTradesSource.asObservable();
  public jobTypes = this.jobTypesSource.asObservable();
  public leaseTypes = this.leaseTypesSource.asObservable();
  public notificationsPanelState = this.notificationsPanelStateSubj.asObservable();

  constructor(private modalService: BsModalService,
              private clientDataService: ClientDataService,
              private authenticatedUserService: AuthenticatedUserService) {
  }


  get client() {
    return this.clientDataService.loggedClientObject;
  }

  get canSeeAnalytics() {
    return this.client?.canSeeAnalytics;
  }

  get clientStatus() {
    return this.client?.clientStatus;
  }

  get canSeeBilling() {
    return this.client?.clientSubscriptionInformation?.billingEnabled;
  }

  get clientName() {
    return this.client.clientName;
  }

  get phoenixClientId() {
    return this.client.clientId;
  }

  get clientType() {
    return this.client.clientType;
  }

  get isSelfService() {
    return this.clientType == ClientType.SELF_SERVICE || this.clientType == ClientType.FREEMIUM;
  }

  get isFreemium() {
    return this.clientType == ClientType.FREEMIUM;
  }

  get isVendorPay() {
    return this.clientType == ClientType.VENDOR_PAY;
  }

  get serviceTypeBehavior() {
    return this.client.serviceTypeBehavior;
  }

  get hasServiceTypeBehavior() {
    return this.serviceTypeBehavior == ServiceTypeBehavior.CERT_GROUP || this.serviceTypeBehavior == ServiceTypeBehavior.COMPANY_GROUP;
  }

  get clientAvatarURL() {
    return this.client.clientAvatarUrl;
  }

  get hasVendors() {
    return this.client.hasVendors;
  }

  get hasTenants() {
    return this.client.hasTenants;
  }

  public getLoggedUser() {
    return this.authenticatedUserService.getLoggedUser();
  }

  get userAvatar() {
    return this.authenticatedUserService.userAvatarSrc;
  }

  public async userPhone(): Promise<string> {
    return await this.authenticatedUserService.getPhone();
  }

  public async userCognitoId(): Promise<string> {
    return await this.authenticatedUserService.getCognitoId();
  }

  public async getUserTitle(): Promise<string> {
    return await this.authenticatedUserService.getTitle();
  }

  public async userPhoenixClientId(): Promise<string> {
    return await this.authenticatedUserService.getPhoenixClientId();
  }

  public async getCertusSystemUserId(): Promise<number> {
    return await this.authenticatedUserService.getCertusSystemUserId();
  }

  public async getUserFullName() {
    return await this.authenticatedUserService.getName();
  }

  public async getUserEmail(): Promise<string> {
    return await this.authenticatedUserService.getEmail();
  }

  public isOneOfTheseClientTypes(clientTypes) {
    return clientTypes.indexOf(this.clientType) > -1;
  }

  public hasClientType(certusClientType: CertusClientType) {
    return certusClientType == CertusClientType.Vendor ? this.hasVendors : this.hasTenants;
  }

  public hasThisClientType(isVendor: boolean) {
    if (isVendor) {
      return this.hasVendors;
    }
    return this.hasTenants;
  }

  public label(labelType: string, isVendor = true) {
    return this.clientDataService.getLabel(labelType, false, isVendor);
  }

  public pluralLabel(labelType: string, isVendor = true) {
    return this.clientDataService.getLabel(labelType, true, isVendor);
  }

  setJobTypes(jobTypes: Array<any>): void {
    this.jobTypesArr = jobTypes;
    for (let jt of this.jobTypesArr) {
      this.jobTypesMap[jt['id']] = jt['name'];
    }
    this.jobTypesSource.next(this.jobTypesArr);
  }

  getJobTypes(callback: (jobTypes: Array<any>) => void = null): any {
    if (callback) {
      if (this.jobTypesArr) {
        callback(this.jobTypesArr);
      } else {
        this.jobTypes.subscribe(callback);
      }

      return;
    }

    return this.jobTypesArr;
  }

  setJobTrades(jobTrades: Array<any>) {
    this.jobTradesArr = jobTrades;
    for (let jt of this.jobTradesArr) {
      this.jobTradesMaps[jt['id']] = jt['name'];
    }
    this.jobTradesSource.next(this.jobTradesArr);
  }

  setLeaseTypes(leaseTypes: Array<any>) {
    this.leaseTypesArr = leaseTypes;
    for (let lt of this.leaseTypesArr) {
      this.leaseTypesMap[lt['id']] = lt['name'];
    }

    this.leaseTypesSource.next(this.leaseTypesArr);
  }

  getLeaseTypes(callback: (leaseTypes: Array<any>) => void = null): any {
    if (callback) {
      if (this.leaseTypesArr) {
        callback(this.leaseTypesArr);
      } else {
        this.leaseTypes.subscribe(callback);
      }

      return;
    }

    return this.leaseTypesArr;
  }

  getJobTrades(callback: (jobTrades: Array<any>) => void = null): any {
    if (callback) {
      if (this.jobTradesArr) {
        callback(this.jobTradesArr);
      } else {
        this.jobTrades.subscribe(callback);
      }

      return;
    }

    return this.jobTradesArr;
  }

  public showAlertMessage(title: string, type: string) {
    this.alertMessageSubject.next({ title, type });
  }

  public showAlertMessages(titles: string[], type: string) {
    this.alertMessagesSubject.next({ titles, type });
  }

  public toggleNotificationsPanel() {
    this.notificationsPanelStateSubj.next(!this.notificationsPanelState);
  }

  public async confirmModal(title: string, modalClasses: string = 'modal-s medium modal-dialog-centered'): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.modalPromiseResolve = resolve;
      this.modalConfirmTitleSubject.next(title);
      this.modalRef = this.modalService.show(this.modalConfirmTemplate, {
        class: modalClasses
      });
      const modalHideSub = this.modalService.onHide.subscribe((reason) => {
        if (reason) {
          resolve(false);
        }
        modalHideSub.unsubscribe();
      });
    });
  }

  public async informationModal(title: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.modalPromiseResolve = resolve;
      this.modalInformationTitleSubject.next(title);
      this.modalRef = this.modalService.show(this.modalInformationTemplate, {
        class: 'modal-md modal-dialog-centered',
        backdrop: true,
        ignoreBackdropClick: true

      });
      const modalHideSub = this.modalService.onHide.subscribe((reason) => {
        if (reason) {
          resolve(false);
        }
        modalHideSub.unsubscribe();
      });
    });
  }

  public async clientDelinquentModal(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.modalPromiseResolve = resolve;
      this.modalRef = this.modalService.show(this.modalClientDelinquentTemplate, {
        class: 'modal-md modal-dialog-centered',
        backdrop: true,
        ignoreBackdropClick: true

      });
      const modalHideSub = this.modalService.onHide.subscribe((reason) => {
        if (reason) {
          resolve(false);
        }
        modalHideSub.unsubscribe();
      });
    });
  }

  public confirm(): void {
    if (this.modalPromiseResolve) {
      this.modalPromiseResolve(true);
      this.modalRef.hide();
    }
  }

  public decline(): void {
    if (this.modalPromiseResolve) {
      this.modalPromiseResolve(false);
      this.modalRef.hide();
    }
  }

  analyticsFeatureEnabledForCurrentUser() {
    const features = this.client?.features;
    const usersWhoCanUseNewFeature = features.find((f: any) => f.featureKey == 'newAnalytics')?.enabledForUserIds;
    const currentUserId = this.authenticatedUserService?.getLoggedUser()?.cognitoId;

    return usersWhoCanUseNewFeature.includes(currentUserId);
  }

  waiverFeatureEnabledForCurrentUser() {
    const features = this.client?.features;
    const usersWhoCanUseNewFeature = features.find((f: any) => f.featureKey == 'waiverDemo')?.enabledForUserIds;
    const currentUserId = this.authenticatedUserService?.getLoggedUser()?.cognitoId;
    return usersWhoCanUseNewFeature.includes(currentUserId);
  }

  newAddVendorFlowFeatureEnabledForCurrentUser() {
    const features = this.client?.features;
    const usersWhoCanUseNewFeature = features.find((f: any) => f.featureKey == 'newAddVendorFlow')?.enabledForUserIds;
    const currentUserId = this.authenticatedUserService?.getLoggedUser()?.cognitoId;
    return usersWhoCanUseNewFeature.includes(currentUserId);
  }

  public waiverId: number = 1;
  public waivedLimitIds: number[] = [];

  public waivers: any[] = [ /*
    {
      'isWaived': false,
      'waiverId': 1,
      'taskType': 'Waiver Request',
      'reviewUser': {
        'clientId': 402,
        'userEmail': 'rmarinaro@getbcs.com',
        'userName': 'Ryan Marinaro',
        'userId': '9f93066e-2204-4147-8a1c-078eca7c625b',
        'status': 'CONFIRMED',
        'avatarUrl': null,
        'lastLogin': '2024-09-10T19:15:34.724641-04:00',
        'userRole': {
          'clientRoleId': 1,
          'name': 'ADMIN',
          'displayName': 'Admin User',
          'permissions': [
            {
              'securable': 'ALL',
              'operation': 'ALL'
            }
          ]
        },
        'enabled': true
      },
      'associatedRecordID': 312959,
      'companyCertificateID': 446485,
      'requirement': {
        'complianceLevel': 0,
        'isEditable': true,
        'differentFromOriginalInsuranceRequirementsCategory': true,
        'limitId': 10822,
        'limitName': 'Each Occurrence',
        'providedAmount': null,
        'requiredAmount': 1000000
      },
      'requestedAmt': 5000,
      'description': 'test',
      'isCompleted': false,
      'status': 'Pending',
      'taskTitle': 'Waiver request - ryan freemium test',
      'associatedRecord': 'ryan freemium test',
      'assignedTo': 'Ryan Marinaro',
      'userRequestingWaiver': 'Ryan Marinaro',
      'isClosing': false
    } */
  ];

  createWaiverStub(selectedWaiverUser: any, companyName: string, companyId: number, certificateId: number, requirementRecord: any, requestedLimitAmount: number, description: string) {
    this.waivers.push({
      isWaived: false,
      waiverId: this.waiverId++,
      taskType: 'Waiver Request',
      reviewUser: selectedWaiverUser,
      associatedRecordID: companyId,
      companyCertificateID: certificateId,
      requirement: requirementRecord,
      requestedAmt: requestedLimitAmount,
      description: description,
      isCompleted: false,
      status: 'Pending',
      taskTitle: 'Waiver request - ' + companyName,
      associatedRecord: companyName,
      assignedTo: selectedWaiverUser.userName,
      userRequestingWaiver: selectedWaiverUser.userName
    });
  }

  approveWaiver(waiverId: number) {
    let waiver = this.waivers.find(w => w.waiverId == waiverId);
    if (!waiver) {
      return;
    }
    waiver.isCompleted = true;
    this.waivedLimitIds.push(waiver.requirement.limitId);
  }

  isLimitWaived(limitId: number): boolean {
    return this.waivedLimitIds.includes(limitId);
  }

  isClientSwitching(setLoader: boolean) {
    this.displayClientSwitchLoader = setLoader;
  }

}
