<div class="left-menu">
  <div class="left-menu-header">
    <br />
    <phx-icon customClass="standardImage" h="42" mb="10" mr="0" src="bcs-new-logo" w="158"></phx-icon>
    <div (click)="showSwitchClientsDropdown()"
         class="header-title px-4 mt-15">
      <span [title]="appService.clientName" class="mr-2 client-name">{{ appService.clientName }}</span>
      <div [ngClass]="switchClientsDropdownShown ? 'rotate-arrow' : ''" class="header-arrow">
        <phx-icon *ngIf="clientSwitcherClients?.length > 0" mr="10" size="10" src="filled-dropdown-arrow"></phx-icon>
      </div>
    </div>
    <div *ngIf="switchClientsDropdownShown" class="client-dropdown-container">
      <div class="client-item input">
        <phx-input (changeEventEmitter)="filterClients($event)"
                   [inputValue]="clientSearchValue"
                   size="w-100"
                   class="client-item-search"
                   inputType="text"
                   placeholder="Search for Client">
        </phx-input>
      </div>
      <div *ngIf="filteredClientSwitcherClients.length == 0" class="client-item">
        <div class="name">No Clients Found</div>
      </div>
      <div (click)="switchClients(client.phoenixClientId, client.name)"
           *ngFor="let client of filteredClientSwitcherClients; let i = index;"
           class="client-item">
        <div class="name">{{ client.name }}</div>
      </div>
    </div>
  </div>
  <div class="left-menu-body">
    <div *ngIf="appService.clientStatus != 'DELINQUENT'">
      <div class="menu-item" data-testid="vendors-menu-tab" routerLinkActive="active">
        <a class="parent-item-my-vendor parent-item">
          <div class="parent-item-container">
            <phx-icon customClass="menu-icon" h="25" mr="15" src="my-vendors" w="25"></phx-icon>
            <div>My {{ 'Vendor' | plural }}</div>
          </div>
        </a>
        <div class="dropdown-content">
          <a class="dropdown-item" data-testid="vendors-menu-item" id="my-accounts" routerLink="my-accounts/v"
             routerLinkActive="active-sublink">
            {{ 'Vendor' | plural }}
          </a>
          <a [ngClass]="!appService.hasVendors ? 'disabled' : ''" class="dropdown-item" data-testid="jobs-menu-item"
             id="jobs" routerLink="my-accounts/j" routerLinkActive="active-sublink">
            {{ 'Job' | plural }}
          </a>
        </div>
      </div>
      <div *ngIf="userPermissionsService.canReadTenants()" class="menu-item" data-testid="tenants-menu-tab"
           routerLinkActive="active">
        <a class="parent-item">
          <div class="parent-item-container">
            <phx-icon customClass="menu-icon" h="25" mr="15" src="my-tenants" w="25"></phx-icon>
            <div>My {{ 'Tenant' | plural }}</div>
          </div>
        </a>
        <div class="dropdown-content">
          <a class="dropdown-item" data-testid="my-tenants-tab" id="my-tenants" routerLink="my-accounts/t"
             routerLinkActive="active-sublink">
            {{ 'Tenant' | plural }}
          </a>
          <a [ngClass]="!appService.hasTenants ? 'disabled' : ''" class="dropdown-item" data-testid="my-leases-tab"
             id="leases" routerLink="my-accounts/l" routerLinkActive="active-sublink">
            {{ 'Lease' | plural }}
          </a>
        </div>
      </div>
      <div class="menu-item" routerLinkActive="active">
        <a class="parent-item" routerLink="marketplace">
          <div class="parent-item-container">
            <phx-icon customClass="menu-icon" h="25" mr="15" src="bcs" w="25"></phx-icon>
            <div>BCS Network</div>
          </div>
        </a>
      </div>
      <div *ngIf="userPermissionsService.canReadJobs()" class="menu-item" routerLinkActive="active">
        <a class="parent-item" routerLink="job-posting">
          <div class="parent-item-container">
            <phx-icon customClass="menu-icon" h="25" mr="15" src="job-postings" w="25"></phx-icon>
            <div>My Postings</div>
          </div>
        </a>
      </div>
      <div *ngIf="userPermissionsService.canReadMessages()" class="menu-item" routerLinkActive="active">
        <a class="parent-item" routerLink="messages">
          <div class="parent-item-container">
            <phx-icon customClass="menu-icon" h="25" mr="15" src="messages" w="25"></phx-icon>
            <div>Messages</div>
          </div>
        </a>
      </div>
      <div *ngIf="userPermissionsService.canReadLocations()" class="menu-item" data-testid="locations-tab"
           routerLinkActive="active">
        <a class="parent-item" routerLink="locations">
          <div class="parent-item-container">
            <phx-icon customClass="menu-icon" h="25" mr="15" src="locations" w="25"></phx-icon>
            <div>My {{ 'Location' | plural }}</div>
          </div>
        </a>
      </div>
      <div *ngIf="userPermissionsService.canReadJobs()" class="menu-item" routerLinkActive="active">
        <a class="parent-item" routerLink="my-tasks">
          <div class="parent-item-container">
            <phx-icon customClass="menu-icon" h="25" mr="15" src="task" w="25"></phx-icon>
            <div>My Tasks</div>
          </div>
        </a>
      </div>
      <div *ngIf="appService.canSeeAnalytics" class="menu-item" routerLinkActive="active">
        <a [routerLink]="['/main/analytics', !appService.hasVendors ? 'tenant-analytics' : 'vendor-analytics']"
           class="parent-item">
          <div class="parent-item-container">
            <phx-icon customClass="menu-icon" h="25" mr="15" src="analytics" w="25"></phx-icon>
            <div>Analytics</div>
          </div>
        </a>
      </div>
    </div>
    <div *ngIf="userPermissionsService.canReadClientSettings()"
         class="menu-item"
         data-testid="settings-tab"
         routerLinkActive="active">
      <a class="parent-item" routerLink="company-profile">
        <div class="parent-item-container">
          <phx-icon customClass="menu-icon" h="25" mr="15" src="gear" w="25"></phx-icon>
          <div>Settings</div>
        </div>
      </a>
    </div>
    <br />
  </div>
</div>
